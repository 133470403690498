import React, { useState, useEffect } from 'react';

const Counter = () => {
    const [text, setText] = useState('0'); // State to hold the count
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Increment and redirect when the component mounts
    useEffect(() => {
        const incrementAndRedirect = async () => {
            try {
                setLoading(true); // Set loading to true

                // Fetch current count from the backend
                const response = await fetch('https://qr-counter-back20241129092445.azurewebsites.net/api/count');
                if (!response.ok) {
                    throw new Error('Failed to fetch count');
                }
                const data = await response.json();
                const currentCount = data.count || 0;
                const incrementedNumber = currentCount + 1; // Increment the number

                // Update the local state with the incremented number
                setText(String(incrementedNumber));

                // Call the backend API to save the incremented number
                const updateResponse = await fetch('https://qr-counter-back20241129092445.azurewebsites.net/api/increment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ newValue: incrementedNumber }), // Send new value to backend
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to save the updated count.');
                }

                console.log('Count incremented and saved successfully.');

                // Redirect to wedding website
                window.location.replace('https://www.theknot.com/us/kaitlin-zeldenrust-and-charles-ayeni-may-2025');
            } catch (error) {
                console.error('Error during the redirect process:', error);
                setError('Failed to increment count or redirect');
            } finally {
                setLoading(false); // Set loading to false
            }
        };

        incrementAndRedirect(); // Call the function to increment and redirect
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <p>Redirecting...</p>
        </div>
    );
};

export default Counter;
